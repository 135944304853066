import modals from './modals'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  modules: {
    modals
  }
}
