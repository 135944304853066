<template>
  <div class="section__wrapper">
    <RouterLink :to="{ name: 'HOME' }" tag="div" class="page-back">
      <div class="page-back__arrow"></div>
      <div class="page-back__name">
        Об авторе
      </div>
    </RouterLink>
    <section>
      <div class="avatar">
        <img
          class="avatar__image"
          :src="require('@/assets/ava.png')"
        />
      </div>
      <div class="block__name">
        Резников Константин
      </div>
      <div class="block__head">
        Fullstack-разработчик, программист, IT-специалист
      </div>
      <div class="block__text">
        Главный специалист по разработке в одной из ведущих компаний страны.
        Преподаватель ВУЗа по разработке и программированию.
        Частный репетитор по информатике и программированию для подготовки к ЕГЭ, ОГЭ, школьной программы и студентов начальных курсов.
        Разработчик методических пособий для ВУЗов и методик обучения.
      </div>
      <div class="block__head">
        Учебная и научная деятельность
      </div>
      <div class="block__text">
        В 2015 году закончил 11 классов в обычной школе Тамбовской области. Сдав ЕГЭ, поступил на бюджет в Воронежский государственный университет, где окончил бакалавриат в 2019 году и тогда же продолжил обучение в магистратуре. В 2021 году защитил магистерскую диссертацию по теме “Разработка веб-приложения для визуализации трехмерных поверхностей”. С 2021 года является преподавателем в ВУЗе и продолжает обучение в аспирантуре. Ведет научную деятельность в области разработки и готовит кандидатскую диссертацию. Частные занятия и репетиторскую деятельность осуществляет с 2016 года, начав преподавать еще будучи студентом бакалавриата.
      </div>
      <div class="block__head">
        Разработка и программирование
      </div>
      <div class="block__text">
        Программированием начал увлекаться еще в школе, а с компьютером и техникой “на ты” совсем с раннего детства. Профессионально начал заниматься разработкой с 2016 года. Тогда еще просто разрабатывая небольшие решения и проекты, которые уже выходили за рамки обычных лабораторных работ в университете. С 2017 года занимается коммерческой разработкой в масштабных проектах, сотрудничая с крупными заказчиками, в том числе - иностранными.
      </div>
    </section>
  </div>
</template>

<script>
import { helpModal } from "@/mixins/modals";

export default {
  name: 'AboutPage',
  mixins: [helpModal],
}
</script>

<style lang="less">
.avatar {
  text-align: center;
  padding: 30px 0;

  &__image {
    max-width: 200px;
    width: 100%;
    border: 20px solid white;
  }
}
</style>
