import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../components/pages/Home.vue';
import AboutPage from '../components/pages/About.vue';
import ProjectsPage from '../components/pages/Projects.vue';
import LinksPage from '../components/pages/Links.vue';
import { RouteNames } from './routes';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: RouteNames.HOME,
        component: HomePage
    },
    {
        path: '/about',
        name: RouteNames.ABOUT,
        component: AboutPage
    },
    {
        path: '/projects',
        name: RouteNames.PROJECTS,
        component: ProjectsPage
    },
    {
        path: '/links',
        name: RouteNames.LINKS,
        component: LinksPage
    }
];
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});
export default router;
