<template>
  <div class="container__wrapper">
    <div class="container">
      <div class="container__header" @click="() => clickMenu()">
        О, привет!
        <div class="container__header__sub">
          reznikovk.ru
        </div>

        <div class="container__audio" @click.stop>
          <AudioButton :audio-on="audioOn" @change="(v) => setAudio(v)" />
          <audio
            class="audio"
            ref="player"
          >
            <source src="@/assets/tick.mp3" type="audio/mp3"/>
          </audio>
          <audio
            class="audio"
            ref="check"
          >
            <source src="@/assets/check.mp3" type="audio/mp3"/>
          </audio>
        </div>
      </div>
      <div class="container__content">
        <div class="menu menu--full">
          <div
            v-for="i in menu"
            :key="i.label"
            class="menu__item"
            :class="{ active: $route.path === i.route }"
            @mouseover="(e) => tick(e, i)"
            @click="(e) => clickMenu(i)"
          >
            {{ i.label }}
          </div>
        </div>
        <transition name="route" mode="out-in">
          <div v-if="$route.name === 'HOME'" class="menu menu--mobile">
            <div
              v-for="i in menu"
              :key="i.label"
              class="menu__item"
              :class="{ active: $route.path === i.route }"
              @mouseover="(e) => tick(e, i)"
              @click="(e) => clickMenu(i)"
            >
              {{ i.label }}
            </div>
          </div>
        </transition>
        <div class="container__main">
          <transition name="route" mode="out-in">
            <slot />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioButton from '@/components/parts/AudioButton'

export default {
  name: 'PageLayout',
  components: {
    AudioButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      audioOn: false
    }
  },
  computed: {
    menu () {
      return [
        {
          label: 'Об авторе',
          route: '/about'
        },
        {
          label: 'Проекты',
          route: '/projects'
        },
        {
          label: 'Ссылки',
          route: '/links'
        },
      ]
    }
  },
  mounted () {
    this.$slots.mobile = this.$slots.default
  },
  methods: {
    setAudio (v) {
      this.audioOn = v
      this.check()
    },
    tick (e) {
      const { target } = e
      if (this.audioOn && !target.classList.contains('active')) {
        const audio = this.$refs.player
        audio.pause()
        audio.currentTime = 0.25
        audio.play()
      }
    },
    check () {
      if (this.audioOn) {
        const audio = this.$refs.check
        audio.pause()
        audio.currentTime = 0
        audio.play()
      }
    },
    clickMenu (item) {
      if (!item) {
        if (this.$route.name === "HOME") {
          return
        }
        this.check()
        this.$router.push({ name: "HOME" })
        return
      }

      if (this.$route.path !== item.route) {
        this.check()
        this.$router.push({ path: item.route })
      }
    }
  }
}
</script>

<style scoped lang="less">
.audio {
  display: none;
}

.route {
  &-enter-active, &-leave-active {
    transition: 0.25s;
  }
  &-enter {
    transform: translateY(100%);
    opacity: 0;
  }
  &-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.menu {
  width: 300px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  font-size: 20px;
  font-family: 'Press Start 2P', system-ui;

  &--full {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  &--mobile {
    width: 100%;
    display: none;
    @media screen and (max-width: 900px) {
      display: grid;
    }
  }

  &__item {
    background-color: rgba(79, 79, 79, 0.7);
    padding: 16px;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: rgba(79, 79, 79, 0.85);
    }

    &.active {
      background-color: white;
      color: #5d5d5d;
      cursor: default;
    }
  }
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 10px;
  overflow: hidden;
  max-width: 900px;
  max-height: 700px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    max-height: 90%;
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #022E40;
    background-image: url('@/assets/bg2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__header {
    font-family: 'Press Start 2P', system-ui;
    color: white;
    text-align: center;
    font-size: 32px;
    cursor: pointer;
    position: relative;
    height: 10%;
    margin-bottom: 40px;

    @media screen and (max-width: 900px) {
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__sub {
      color: #b4b4b4;
      font-size: 18px;
      margin-top: 10px;

      @media screen and (max-width: 900px) {
        font-size: 14px;
      }
    }
  }

  &__audio {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    top: 0;
  }

  &__main {
    flex: 1;
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }

  &__content {
    display: flex;
    flex: 1;
    align-items: flex-end;
    overflow: hidden;
    gap: 10px;

    @media screen and (max-width: 900px) {
      gap: 0;
    }
  }
}
</style>
