<template>
  <div class="audio-button">
    <transition name="alert">
      <div v-if="showAlert" class="audio-button__alert">
        Тык ->
      </div>
    </transition>
    <audio
      class="audio-button__player"
      ref="player"
    >
      <source src="@/assets/melody.mp3" type="audio/mp3"/>
    </audio>
    <img
      ref="handler"
      class="audio-button__handler"
      :src="audioOn ? require('@/assets/audio.png') : require('@/assets/mute.png')"
      @mouseover="showAlert = false"
      @click="() => togglePlay()"
    />
  </div>
</template>

<script>
export default {
  name: 'AudioButton',
  props: {
    audioOn: Boolean
  },
  data () {
    return {
      showAlert: false
    };
  },
  mounted () {
    const audio = this.$refs.player
    audio.addEventListener("ended", this.endListener)
    audio.volume = 0.2
    if (!this.showAlert) {
      setTimeout(() => {
        this.showAlert = true
        setTimeout(() => {
          this.showAlert = false
        }, 5000)
      }, 1000)
    }
  },
  methods: {
    togglePlay () {
      if (this.audioOn) {
        this.$refs.player.pause()
      } else {
        this.$refs.player.play()
      }
      this.$emit('change', !this.audioOn)
    },
    endListener () {
      const audio = this.$refs.player
      audio.currentTime = 0
      audio.play()
    }
  }
}
</script>

<style scoped lang="less">
@keyframes alerting {
  0% {
    transform: translateX(-40px);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-40px);
  }
  35% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  45% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-40px);
  }
  75% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(-10px);
  }
  85% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

.audio-button {
  box-sizing: border-box;
  transition: 0.2s;
  position: relative;

  &__alert {
    font-family: 'Press Start 2P', system-ui;
    color: white;
    position: absolute;
    right: 140%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 20px;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__player {
    display: none;
  }

  &__handler {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &:hover {
    transform: scale(0.9);
  }
}

.alert {
  &-enter-active {
    animation: alerting 5s;
  }
  &-leave-active {
    transition: 5s;
  }
  &-leave-to {
    opacity: 0;
  }
}

</style>
