<template>
  <div class="section__wrapper">
    <RouterLink :to="{ name: 'HOME' }" tag="div" class="page-back">
      <div class="page-back__arrow"></div>
      <div class="page-back__name">
        Ссылки
      </div>
    </RouterLink>
    <section>
      <div class="block__head">
        GitHub - <a href="https://github.com/reznikovkg" target="_blank">@reznikovkg</a>
      </div>
      <div class="block__head">
        Telegram - <a href="https://t.me/reznikovkg" target="_blank">@reznikovkg</a>
      </div>
    </section>
  </div>
</template>

<script>
import { helpModal } from "@/mixins/modals";

export default {
  name: 'LinksPage',
  mixins: [helpModal],
}
</script>
