<template>
  <div />
</template>

<script>
import { helpModal } from "@/mixins/modals";

export default {
  name: 'HomePage',
  mixins: [helpModal]
}
</script>
